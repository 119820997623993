.recommend {

  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.recommend .div {

  border: 2px none;
  overflow: hidden;
  position: relative;
  width: 780px;
  padding-bottom: 200px;
}

.overlap-bottom-text {
  position: relative;
  padding-bottom: 280px;
}

.recommend .overlap-group-wrapper {
  height: 88px;
  left: 0;

  top: 88px;
  width: 784px;
}

.recommend .overlap {
  margin-bottom: 120px;
}

.recommend .overlap-group {
  background-color: #ffffff;
  height: 88px;
  position: relative;
  width: 780px;
}

.recommend .icon-fanhui {
  height: 56px !important;
  left: 32px !important;
  position: absolute !important;
  top: 16px !important;
  width: 56px !important;
}

.recommend .text-wrapper {
  color: var(--ctextwbgprimary-90);
  font-family: var(--font-16-24-medium-font-family);
  font-size: var(--font-16-24-medium-font-size);
  font-style: var(--font-16-24-medium-font-style);
  font-weight: var(--font-16-24-medium-font-weight);
  left: 184px;
  letter-spacing: var(--font-16-24-medium-letter-spacing);
  line-height: var(--font-16-24-medium-line-height);

  text-align: center;
  top: 20px;
  white-space: nowrap;
}

.recommend .view {
  height: 56px;
  left: 692px;

  top: 16px;
  width: 56px;
}

.recommend .div-2 {
  background-color: #191919;
  border-radius: 3px;
  height: 6px;
  left: 24px;

  top: 24px;
  width: 6px;
}

.recommend .div-3 {
  background-color: #191919;
  border-radius: 3px;
  height: 6px;
  left: 12px;

  top: 24px;
  width: 6px;
}

.recommend .div-4 {
  background-color: #191919;
  border-radius: 3px;
  height: 6px;
  left: 40px;

  top: 24px;
  width: 6px;
}

.recommend .iphone-x-status-bars {
  background-color: #ffffff;
  height: 88px;
  left: 0;

  top: 0;
  width: 780px;
}

.recommend .battery {
  height: 24px;
  left: 694px;

  top: 36px;
  width: 48px;
}

.recommend .wifi {
  height: 24px;
  left: 650px;

  top: 36px;
  width: 32px;
}

.recommend .cellular-connection {
  height: 22px;
  left: 604px;

  top: 38px;
  width: 32px;
}

.recommend .time-style {
  height: 36px;
  left: 42px;
  overflow: hidden;

  top: 30px;
  width: 108px;
}

.recommend .time {
  color: #000000;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 20px;
  letter-spacing: -1.2px;
  line-height: normal;

  top: 0;
  white-space: nowrap;
}

.recommend .group {
  height: 68px;
  left: 0;

  top: 1908px;
  width: 780px;
}

.recommend .home-indicator-light {
  height: 68px;
  overflow: hidden;
}

.recommend .home-indicator-wrapper {
  background-color: #ffffff;
  height: 72px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 784px;
}

.recommend .home-indicator {
  background-color: #000000;
  border-radius: 5px;
  height: 8px;
  left: 252px;
  position: relative;
  top: 42px;
  width: 280px;
}

.recommend .overlap {
  background-color: #ffffff;
  left: 0;
  position: relative;

}

.recommend .mask-group {
  height: 586px;
  left: 0;

  top: 0;
  width: 780px;
}

.recommend .text-wrapper-2 {
  color: var(--ctextwbgprimary-90);
  font-family: var(--font-20-28-medium-font-family);
  font-size: 30px;
  font-style: var(--font-20-28-medium-font-style);
  font-weight: var(--font-20-28-medium-font-weight);
  left: 40px;
  letter-spacing: var(--font-20-28-medium-letter-spacing);
  line-height: var(--font-20-28-medium-line-height);
  margin-top: 60px;
  top: 624px;
  white-space: nowrap;

}

.recommend .text-wrapper-3 {
  margin-top: 20px;
  font-family: var(--font-14-22-regular-font-family);
  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 88px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);

  text-align: justify;
  top: 704px;
  white-space: normal;
  text-align: left;

}

.recommend hr {
  border: solid 1px #000;
  margin: 80px 0;
  margin-top: 130px;
}

.recommend .element-px {
  height: 40px;
  left: 40px;

  top: 708px;
  width: 40px;
}

.recommend .overlap-2 {
  height: 32px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 32px;
}

.recommend .subtract-wrapper {
  height: 32px;
  left: 0;

  top: 0;
  width: 32px;
}

.recommend .subtract {
  height: 28px;
  left: 4px;

  top: 2px;
  width: 24px;
}

.recommend .ellipse {
  background-color: #ffffff;
  border: 2.4px solid;
  border-color: #999999;
  border-radius: 5.2px;
  height: 10px;
  left: 10px;

  top: 10px;
  width: 10px;
}

.recommend .group-2 {
  
  left: 40px;
  word-break: break-all;
  top: 1102px;
  
}

.recommend .text-wrapper-4 {
  color: #8eb500 !important;
  font-family: var(--font-16-24-medium-font-family);
  font-size: 28px;
  font-style: var(--font-16-24-medium-font-style);

  letter-spacing: var(--font-16-24-medium-letter-spacing);
  line-height: var(--font-16-24-medium-line-height);
  margin-bottom: 10px;
  top: 0;
  white-space: nowrap;

}

.recommend .group-3 {
  height: 44px;
  left: 0;

  top: 80px;
  
}

.recommend .text-wrapper-5 {
  color: var(--ctextlink);
  font-family: var(--font-14-22-regular-font-family);
  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 52px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);

  text-align: justify;
  top: 0;
  white-space: nowrap;

}

.recommend .text-wrapper-5 a {
  text-decoration: none;
  color: #333;
  text-align: justify;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;

}

.recommend .vector-wrapper {
  height: 44px;
  left: 0;

  top: 0;
  width: 44px;
}

.recommend .vector {
  height: 30px;
  left: 8px;

  top: 8px;
  width: 30px;
}

.recommend .group-4 {
  height: 44px;
  left: 264px;

  top: 80px;
  width: 240px;
}

.recommend .image {
  height: 2px;
  left: 40px;
  object-fit: cover;

  top: 776px;
  width: 660px;
}

.recommend .group-5 {

  margin-bottom: 30px;
}

.recommend .text-wrapper-6 {
  color: var(--ctextwbgprimary-90);
  font-family: var(--font-14-22-regular-font-family);
  font-size: 23px;
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 0;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);

  text-align: justify;
  top: 80px;


}

.recommend .text-wrapper-7 {
  color: var(--ctextwbgprimary-90);
  font-family: var(--font-14-22-regular-font-family);
  font-size: 23px;
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  right: 20px;

  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);

  text-align: justify;
  top: 80px;
  white-space: nowrap;

}

.recommend .text-wrapper-8 {
  color: var(--ctextwbgprimary-90);
  font-family: var(--font-14-22-regular-font-family);
  font-size: 23px;
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  right: 20px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);

  text-align: justify;
  top: 140px;
  white-space: nowrap;

}

.recommend .text-wrapper-9 {
  color: var(--ctextwbgprimary-90);
  font-family: var(--font-14-22-regular-font-family);
  font-size: 23px;
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 0;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);

  text-align: justify;
  top: 140px;
  white-space: nowrap;

}

.recommend .text-wrapper-10 {
  color: var(--ctextwbgnormal-60);
  font-family: var(--font-12-20-regular-font-family);
  font-size: var(--font-12-20-regular-font-size);
  font-style: var(--font-12-20-regular-font-style);
  font-weight: var(--font-12-20-regular-font-weight);
  left: 40px;
  letter-spacing: var(--font-12-20-regular-letter-spacing);
  line-height: var(--font-12-20-regular-line-height);

  text-align: justify;
  top: 20px;
  white-space: nowrap;

}

.recommend .text-wrapper-11 {
  color: var(--ctextwbgnormal-60);
  font-family: var(--font-12-20-regular-font-family);
  font-size: var(--font-12-20-regular-font-size);
  font-style: var(--font-12-20-regular-font-style);
  font-weight: var(--font-12-20-regular-font-weight);
  left: 40px;
  letter-spacing: var(--font-12-20-regular-letter-spacing);
  line-height: var(--font-12-20-regular-line-height);

  text-align: justify;
  top: 60px;
  width: 700px;

}

.recommend .overlap-wrapper {
  height: 98px;
  left: 0;

  top: 1810px;
  width: 800px;
}

.recommend .overlap-3 {
  background-color: #ffffff;
  height: 102px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 784px;
}

.recommend .overlap-4 {
  height: 88px;
  left: 508px;

  top: 6px;
  width: 82px;
}

.recommend .view-2 {
  height: 56px;
  left: 14px;

  top: 0;
  width: 56px;
}

.recommend .overlap-group-2 {
  height: 36px;
  left: 10px;
  position: relative;
  top: 12px;
  width: 40px;
}

.recommend .rectangle {
  height: 4px;
  left: 0;

  top: 32px;
  width: 40px;
}

.recommend .img {
  height: 36px;
  left: 2px;

  top: 0;
  width: 36px;
}

.recommend .text-wrapper-12 {
  color: #000000e6;
  font-family: "LiciumFont 2022-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 36px;
  opacity: 0.9;

  text-align: center;
  top: 52px;
  white-space: nowrap;

}

.recommend .overlap-5 {
  height: 88px;
  left: 350px;

  top: 6px;
  width: 82px;
}

.recommend .overlap-6 {
  height: 88px;
  left: 38px;

  top: 6px;
  width: 82px;
}

.recommend .overlap-7 {
  height: 88px;
  left: 194px;

  top: 6px;
  width: 82px;
}

.recommend .overlap-8 {
  height: 36px;
  left: 8px;
  position: relative;
  top: 10px;
  width: 40px;
}

.recommend .rectangle-2 {
  height: 32px;
  left: 2px;

  top: 0;
  width: 36px;
}

.recommend .vector-2 {
  height: 4px;
  left: 10px;

  top: 8px;
  width: 20px;
}

.recommend .vector-3 {
  height: 4px;
  left: 0;

  top: 0;
  width: 40px;
}

.recommend .vector-4 {
  height: 8px;
  left: 18px;

  top: 28px;
  width: 4px;
}

.recommend .vector-5 {
  height: 4px;
  left: 10px;

  top: 16px;
  width: 8px;
}

.recommend .overlap-9 {
  height: 88px;
  left: 664px;

  top: 6px;
  width: 82px;
}

.recommend .img-2 {
  height: 32px;
  left: 8px;

  top: 12px;
  width: 40px;
}

.recommend .img-3 {
  height: 2446px;
  left: -9750px;

  top: 6000px;
  width: 780px;
}

.recommend .img-4 {
  height: 1688px;
  left: -8008px;

  top: 6000px;
  width: 780px;
}

.recommend .img-5 {
  height: 1688px;
  left: -7140px;

  top: 6000px;
  width: 780px;
}

.recommend .img-6 {
  height: 4236px;
  left: -8882px;

  top: 6000px;
  width: 780px;
}

.recommend .translate {
  font-size: 24px !important;
  position: absolute;
  top: 650px;
  left: 83px;
}

.recommend .content {
  padding: 0 80px;
}

.recommend .price {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 5px;
}

.recommend a:link {
  color: #333 !important;
}

.recommend a {
  color: #333 !important;
}

.recommend .text-wrapper-2-2 {
  color: var(--ctextwbgprimary-90);
  font-family: var(--font-20-28-medium-font-family);
  font-size: 30px;
  font-style: var(--font-20-28-medium-font-style);
  font-weight: var(--font-20-28-medium-font-weight);
  left: 40px;
  letter-spacing: var(--font-20-28-medium-letter-spacing);
  line-height: var(--font-20-28-medium-line-height);
  margin-top: 160px;
  top: 624px;
  white-space: nowrap;
  margin-top: 80px;
}

.recommend .daohang {
  position: fixed;
  top: 600px;
  z-index: 99999;
  right: 30px;
  background-color: #f9f9f9;
  padding: 15px 15px;
  border-radius: 20px;
  text-align: center;
}

.recommend .item {
  background-color: #e6e6e6;
  padding: 5px 10px;
  text-align: center;
  font-size: 14px !important;
  margin: 10px 0;
  border-radius: 200px;
}

.recommend a:focus,
a:hover,
a:active,
a:link,
a:visited,
a {

  text-decoration: none !important;
}
.recommend .shangjian{
  margin: 0 auto;
}
.recommend .xiajian{
  margin: 0 auto;
}
.recommend .xieyi{
  color: #8eb500;
  font-size: 20px;
}
.recommend .lianjie{
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 15px;
}
.recommend .text-wrapper-xiao{
  font-size: 15px;
  font-weight: 300 !important;
}

.recommend .text-wrapper-6-xiao span{
  font-weight: 500 !important;
}

.recommend .group-xiao{
  font-weight: 300 !important;
}

.recommend .text-wrapper-6-xiao{
  color: var(--ctextwbgprimary-90);
  font-family: var(--font-14-22-regular-font-family);
  font-size: 23px;
  font-style: var(--font-14-22-regular-font-style);
  
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);

  text-align: justify;
  top: 80px;
  font-weight: 300 !important;
}