.information {
    background-color: #ffffff;
    
    flex-direction: row;
    justify-content: center;
    width: 750px;
    margin: 0 auto;
    padding-bottom: 200px;
    font-size: 24px;
    position: relative;
  }
.information img{
    width: 100%;
}
.information .translate{
  position: absolute;
  top:680px;
  left: 83px;
}
.information .mapItem{
  padding: 40px
}

.information .mapItem img~img{
  margin-top: 40px;
}

.daohang .item{
  padding:5px 35px !important;
}
